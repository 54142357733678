<template>
  <v-dialog
    v-model="dialogProfile"
    max-width="800px"
    @click:outside="$emit('update:dialogProfile', false)"
    origin="right right"
  >
    <v-card>
      <v-container class="py-0">
        <v-row>
          <v-col
            cols="12"
            md="3"
            class="py-8 lightgray d-flex flex-column align-center justify-center"
            :style="
              $vuetify.breakpoint.mdAndUp
                ? 'border-right: 1px solid rgba(0, 0, 0, 0.12) !important;'
                : ''
            "
          >
            <div class="d-flex justify-center align-center">
              <v-hover v-slot:default="{ hover }">
                <v-avatar
                  size="120"
                  style="box-shadow: 0px 0px 0px 3px #14525E;"
                >
                  <v-img
                    :src="
                      usuario.avatar
                        ? apiUrl + usuario.avatar
                        : require('@/assets/avatar_placeholder.png')
                    "
                  >
                    <v-expand-transition>
                      <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out darkblue v-card--reveal white--text"
                        style="height: 34px; "
                      >
                        <v-btn
                          depressed
                          text
                          x-small
                          dark
                          class="mt-1"
                          @click="dialogImages = true"
                        >
                          alterar
                        </v-btn>
                      </div>
                    </v-expand-transition>
                  </v-img>
                </v-avatar>
              </v-hover>
            </div>
            <!-- <v-divider
              style="border-width: 1px; border-color: #208c6e; width: 100%;"
              dark
              class="my-4"
            ></v-divider> -->
            <div class="lightgreen--text text-center font-weight-bold mt-6">
              {{ usuario.name }}
            </div>
          </v-col>
          <v-col cols="12" md="9" class="pa-0">
            <v-card-title class="subtitle-1 px-4 py-2">
              <v-spacer></v-spacer>
              <v-btn icon @click="$emit('update:dialogProfile', false)">
                <v-icon>close</v-icon>
              </v-btn>
            </v-card-title>
            <v-form v-model="valid" ref="form">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model.lazy="usuario.name"
                      label="Nome Completo"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model.lazy="usuario.whats"
                      label="Whats"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      v-model.lazy="usuario.celular"
                      label="Celular"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      :append-icon="show ? 'visibility' : 'visibility_off'"
                      name="senha"
                      label="Senha"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      dense
                      hide-details
                      outlined
                      v-model="usuario.password"
                      class="input-group--focused"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-text-field
                      :append-icon="show ? 'visibility' : 'visibility_off'"
                      name="confirmarSenha"
                      label="Confirmar Senha"
                      :type="show ? 'text' : 'password'"
                      @click:append="show = !show"
                      dense
                      hide-details
                      outlined
                      v-model="usuario.password_confirmation"
                      class="input-group--focused"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
            <v-divider></v-divider>
            <v-card-actions class="pa-3">
              <v-spacer></v-spacer>
              <v-btn
                @click.prevent="updateUsuario(user_id)"
                dark
                depressed
                small
                color="buttons"
              >
                <v-icon left>save</v-icon>
                Salvar
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <SelectImageModal
      :dialogImages.sync="dialogImages"
      origem="usuarios"
      :origem_id="user_id"
    >
      <template v-slot:saveSelected>
        <v-spacer></v-spacer>
        <v-fade-transition>
          <v-btn
            v-if="imagemSelecionada"
            depressed
            dark
            small
            color="toolbar"
            @click="updateAvatar(imagemSelecionada.path_original)"
          >
            Salvar
            <v-icon>save</v-icon>
          </v-btn>
        </v-fade-transition>
      </template>
    </SelectImageModal>
  </v-dialog>
</template>

<script>
import {
  putUsuario,
  getUsuario,
  uploadImage,
  getImages,
} from "@/services/api/usuarios.api.js";

import { mapState } from "vuex";

export default {
  name: "Usuario",
  components: {
    SelectImageModal: () => import("@/components/SelectImageModal"),
  },
  props: {
    dialogProfile: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    usuario: {},
    tab: null,
    show: false,
    dialog: false,
    valid: false,
    loading: false,
    notFound: false,
    original: {},
    rules: {
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "O e-mail precisa ser valido!";
      },
    },
    apiUrl: null,
    avatar: "",
    dialogImages: false,
  }),
  computed: {
    ...mapState("Usuario", {
      isAdmin: (state) => state.usuario.is_admin,
      user_id: (state) => state.usuario.id,
    }),
    ...mapState({
      imagemSelecionada: (state) => state.imagemSelecionada,
    }),
  },
  methods: {
    backHistory() {
      this.$router.go(-1);
    },
    updateUsuario(id) {
      if (JSON.stringify(this.usuario) === JSON.stringify(this.original)) {
        this.$toast.error("Por favor, faça uma mudanca antes de atualizar");
      } else {
        putUsuario(id, this.usuario)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Usuario atualizado com sucesso!");
              this.original = { ...this.usuario };
              if (this.user_id === this.user_id) {
                this.$store.dispatch("Usuario/getUsuario");
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    fetchUser() {
      getUsuario(this.user_id)
        .then((response) => {
          this.usuario = response;
          this.original = { ...response };
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateAvatar(image) {
      this.usuario.avatar = image;
      this.updateUsuario(this.user_id);
      this.dialogImages = false;
    },
  },
  mounted() {
    if (process.env.VUE_APP_API_URL) {
      this.apiUrl = process.env.VUE_APP_API_URL;
    }
    if (this.user_id) {
      this.fetchUser();
    } else {
      this.notFound = "El usuario no ha sido encontrado";
    }
  },
  created() {},
};
</script>

<style scoped lang="scss">
.v-card--reveal {
  align-items: flex-start;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
